import "./ErrorModal.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import { Button, Modal } from "react-bootstrap";
import ErrorIcon from "../../assets/imgs/error-icon.svg";
import DefaultButton from "../DefaultButton/DefaultButton";

const ErrorModal = ({
  title,
  description,
  confirmTitle,
  backTitle,
  onConfirm,
  onBack,
  onExit,
  show,
}) => (
  <Modal show={show} onHide={onExit}>
    <div className="confirmation-modal">
      <div className="confirmation-modal__icon">
        <img src={ErrorIcon} alt="Forbidden Icon" />
      </div>
      <div className="confirmation-modal__title">
        <h4>{title}</h4>
      </div>
      <div className="confirmation-modal__description">
        <p>{description}</p>
      </div>
      <div className="confirmation-modal__actions">
        <DefaultButton
          title={confirmTitle}
          onClick={onConfirm}
        />
        {backTitle && (
          <DefaultButton
            title={backTitle}
            onClick={onBack}
            type="secondary"
          />
        )}
      </div>
    </div>
  </Modal>
);

ErrorModal.defaultProps = {
  title: "Erro",
  description: "Erro ao executar operação!",
  confirmTitle: "Tentar novamente",
  backTitle: "Voltar",
  onConfirm: () => { },
  onBack: () => { },
  onExit: () => { },
  show: false,
}

export default ErrorModal;
