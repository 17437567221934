import "./Home.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import loginService from "../../services/login-service";
import userService from "../../services/user-service";
import foodstuffService from "../../services/foodstuff-service";
import { GlobalContext } from "../GlobalContext";
import TextInput from "../../components/TextInput/TextInput";
import NumericPagination from "../../components/NumericPagination/NumericPagination";
import DefaultFoodstuffIcon from "../../assets/imgs/default-foodstuff-icon.svg";
import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";

import UserSymbolIcon from "../../assets/imgs/user-symbol-icon.svg";
import UserNetworkIcon from "../../assets/imgs/user-network-icon.svg";
import FoodCartIcon from "../../assets/imgs/food-cart-icon.svg";
import SimplePagination from "../../components/SimplePagination/SimplePagination";

import DefaultUserIcon from "../../assets/imgs/default-user-icon.png";
import DefaultButton from "../../components/DefaultButton/DefaultButton";

const Home = () => {
  const [currentUser, setCurrentUser] = useState();
  const [foodstuffs, setFoodstuffs] = useState([]);
  const [usersResponse, setUsersResponse] = useState({});
  const [searchedUserName, setSearchedUserName] = useState("");
  const [users, setUsers] = useState([]);
  const [currentUsersPage, setCurrentUsersPage] = useState(1);
  const [foodstuffsResponse, setFoodstuffsResponse] = useState({});
  const [currentFoodstuffsPage, setCurrentFoodstuffsPage] = useState(0);
  const { setSelectedScreen, isLoading, setIsLoading } = useContext(GlobalContext);

  const getFormattedRole = (user) => {
    const mappedRoles = {
      ROLE_ADMIN: "Administrador",
      ROLE_NUTRITIONIST: "Nutricionista",
      ROLE_USER: "Usuário"
    }

    return mappedRoles[user.main_role];
  };

  const fetchUsers = useCallback(async (name, page) => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const usersResponse = await userService.getUsers(page ?? currentUsersPage, 8, name);
    setUsersResponse(usersResponse);
    setUsers(usersResponse.content);
    setCurrentUsersPage(page + 1);
    setIsLoading(false);
  }, []);

  const fetchFoodstuffs = useCallback(
    async (page) => {
      setIsLoading(true);
      setCurrentFoodstuffsPage(page);
      try {
        const foodstuffsResponse = await foodstuffService.getFoodstuffs(
          page || 0,
          7,
          "",
          "",
        );
        setFoodstuffsResponse(foodstuffsResponse);
        setFoodstuffs(foodstuffsResponse.content);
      } catch (error) {
        console.log("Erro ao buscar alimentos", error);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFoodstuffsPage]
  );

  const formatFoodstuffName = (name) => {
    return name.length > 20 ? `${name.substring(0, 20)}...` : name;
  }

  useEffect(() => {
    document.title = "Dashboard";
    setSelectedScreen("home");

    if (!isLoading) {
      setIsLoading(true);
    };
    async function fetchCurrentUser() {
      const userFound = await loginService.getCurrentUser();
      setCurrentUser(userFound);
    }

    async function fetchData() {
      await Promise.all([
        fetchCurrentUser(),
        fetchUsers('', 0),
        fetchFoodstuffs(0),
      ]);
      setIsLoading(false);
    }

    fetchData();
  }, []);

  const InfoCard = ({ icon, title, value }) => (
    <div className="right-side__content__metrics__item">
      <div className="right-side__content__metrics__item__icon">
        <img src={icon} alt="Icon" />
      </div>
      <div className="right-side__content__metrics__item__info">
        <div className="right-side__content__metrics__item__value">
          <p>{value ?? 0}</p>
        </div>
        <div className="right-side__content__metrics__item__title">
          <p>{title}</p>
        </div>
      </div>
    </div>
  );

  return (
    <Col className="home">
      <div className="home__content">
        <div className="home__content__header">
          <h1>Dashboard</h1>
        </div>
        <div className="right-side__content__metrics">
          <InfoCard icon={UserSymbolIcon} title="Usuários registrados" value={usersResponse.total_elements} />
          <InfoCard icon={UserNetworkIcon} title="Nutricionistas registrados" value={0} />
          <InfoCard icon={FoodCartIcon} title="Alimentos registrados" value={foodstuffsResponse.total_elements} />
        </div>
        <div className="home__content__lists">
          <div className="content__left">
            <div className="content__left__foodstuffs">
              <div className="home__foodstuffs__header">
                <div className="home__foodstuffs__header__title">
                  <h3>Alimentos Registrados</h3>
                </div>
                <div className="home__foodstuffs__header__actions">
                  <DefaultButton
                    title="Novo alimento"
                    type="tertiary"
                    onClick={() => (window.location.href = "/create-foodstuff")}
                  />
                </div>
              </div>
              <div className="home__foodstuffs__list">
                <div className="home__foodstuffs__list__header">
                  <div className="home__foodstuffs__list__header__image">
                    Imagem
                  </div>
                  <div className="home__foodstuffs__list__header__name">Nome</div>
                  <div className="home__foodstuffs__list__header__type">Tipo</div>
                  <div className="home__foodstuffs__list__header__actions" />
                </div>
                <div className="home__foodstuffs__list__items">
                  {foodstuffs.map((foodstuff) => (
                    <div className="home__foodstuffs__list__item">
                      <div className="home__foodstuffs__list__item__image">
                        <img src={foodstuff.photo_url ?? DefaultFoodstuffIcon} alt="Banana icon" />
                      </div>
                      <div className="home__foodstuffs__list__item__name">
                        {formatFoodstuffName(foodstuff.name)}
                      </div>
                      <div className="home__foodstuffs__list__item__type">
                        {foodstuff.group}
                      </div>
                      <div className="home__foodstuffs__list__item__actions">
                        <div className="home__foodstuffs__list__item__actions__edit">
                          <Button
                            onClick={() =>
                              (window.location.href = `/foodstuffs/${foodstuff.id}/editing`)
                            }
                          >
                            <img src={ChevronRightIcon} alt="Right Arrow" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="home__foodstuffs__list__footer">
                  <SimplePagination
                    currentPage={currentFoodstuffsPage}
                    totalPages={foodstuffsResponse.total_pages}
                    onSelectPage={(page) => {
                      setCurrentFoodstuffsPage(page);
                      fetchFoodstuffs(page);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="right-side__content__lists">
            <div className="right-side__content__lists__users">
              <div className="users__title">
                <h3>Usuários</h3>
              </div>
              <div className="users__items">
                {users.map((user) => (
                  <div className="users__item">
                    <div className="users__item__avatar">
                      <img src={user.photo_url ?? DefaultUserIcon} alt="User Icon" />
                    </div>
                    <div className="users__item__info">
                      <div className="users__item__name">
                        <p>{user.name}</p>
                      </div>
                      <div className="users__item__role">
                        <p>{getFormattedRole(user)}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="users__footer">
                <NumericPagination
                  currentPage={currentUsersPage === 0 ? 1 : currentUsersPage}
                  totalPages={usersResponse.total_pages}
                  onSelectPage={async (page) => {
                    await fetchUsers(searchedUserName, page);
                  }}
                  pagesPerView={5}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default Home;

Home.propTypes = {};
