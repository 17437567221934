import "./Privacy.css";
import "bootstrap/dist/css/bootstrap.min.css";

import TextLogo from "../../assets/imgs/text-logo.svg";
import Menu from "../../assets/imgs/menu.svg";

import CalorieTrackerFeature from "../../assets/imgs/calorie-tracker-feature.svg";
import MealTrackerFeature from "../../assets/imgs/meal-tracker-feature.svg";
import WaterTrackerFeature from "../../assets/imgs/water-tracker-feature.svg";
import CalorieTrackerFeatureMobile from "../../assets/imgs/calorie-tracker-feature-mobile.svg";
import MealTrackerFeatureMobile from "../../assets/imgs/meal-tracker-feature-mobile.svg";
import WaterTrackerFeatureMobile from "../../assets/imgs/water-tracker-feature-mobile.svg";
import CloseButton from "../../assets/imgs/close-button.svg";

import StarIcon from "../../assets/imgs/star-icon.svg";
import NutritionistImage from "../../assets/imgs/nutritionist-image.svg";
import NutritionistImageMobile from "../../assets/imgs/nutritionist-image-mobile.svg";

import DefaultButton from "../../components/DefaultButton/DefaultButton";

import React, { useEffect } from "react";


const Privacy = () => {
  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <>
      {window.innerWidth <= 768 && openMenu ? (
        <div className="menu">
          <div className="menu__header">
            <div className="menu__header__close">
              <a href="javascript:;" onClick={() => setOpenMenu(false)}>
                <img src={CloseButton} alt="menu" />
              </a>
            </div>
          </div>
          <div className="menu__items">
            <a href="javascript:;" className="menu__item"><p>Início</p></a>
            <a href="javascript:;" className="menu__item"><p>Funcionalidades</p></a>
            <a href="javascript:;" className="menu__item"><p>Contato</p></a>
          </div>
          <div className="menu__actions">
            <DefaultButton
              title="Baixar App"
              onClick={() => { }}
            />
            <DefaultButton
              title="Entrar como nutricionista"
              onClick={() => {
                window.location.href = "/login";
              }}
              type="secondary"
              style="menu__actions__login"
            />
          </div>
        </div>
      ) : (
        <div className="landing">
          <div className="landing__header">
            <a href="javascript:;" className="landing__header__logo" onClick={() => {
              window.location.href = "/";
            }}>
              <img src={TextLogo} alt="logo" />
            </a>
            <div className="landing__header__menu">
              <a href="javascript:;" className="landing__header__menu__item"><p>Início</p></a>
              <a href="javascript:;" className="landing__header__menu__item"><p>Funcionalidades</p></a>
              <a href="javascript:;" className="landing__header__menu__item"><p>Contato</p></a>
            </div>
            <div className="landing__header__actions">
              {window.innerWidth >= 768 ? (
                <>
                  <DefaultButton
                    title="Entrar como nutricionista"
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                    type="secondary"
                    style="landing__header__actions__login"
                  />
                  <DefaultButton
                    title="Baixar App"
                    onClick={() => { }}
                  />
                </>
              ) : (
                <a href="javascript:;" onClick={() => setOpenMenu(true)}>
                  <img src={Menu} alt="menu" />
                </a>
              )}
            </div>
          </div>
          <div className="landing__privacy">
            <div className="landing__privacy__title">
              <h1>Termos de Uso e Políticas de Privacidade</h1>
            </div>
            <div className="landing__privacy__description">
              <p>Bem-vindo(a) à Lorie! Ao utilizar nosso aplicativo, você concorda com as seguintes políticas de privacidade. Leia atentamente.</p>
            </div>
            <div className="landing__privacy__updatedAt">
              <p>Atualizado em 10 de Junho de 2024</p>
            </div>
            <div className="landing__privacy__content">
              <div className="landing__privacy__paragraphs">
                <div className="landing__privacy__paragraph">
                  <h3>1 . Descrição da Aplicação</h3>
                  <p>A Lorie é uma aplicação iOS destinada à gestão de calorias diárias consumidas pelo usuário. As principais funcionalidades incluem:</p>
                  <div className="landing__privacy__bulletPoints">
                    <ul>
                      <li>Gestão de calorias diárias.</li>
                      <li>Recomendação de consumo diário de calorias.</li>
                      <li>Registro de refeições com base em alimentos registrados no sistema.</li>
                      <li>Contagem de calorias, proteínas, sódio, carboidratos, e outros nutrientes.</li>
                    </ul>
                  </div>
                </div>
                <div className="landing__privacy__paragraph">
                  <h3>2 . Coleta e Uso de Dados</h3>
                  <p>2.1 Dados Coletados</p>
                  <div className="landing__privacy__bulletPoints">
                    <ul>
                      <li>Nome</li>
                      <li>E-mail</li>
                      <li>Sexo</li>
                      <li>Data de nascimento</li>
                      <li>Dados opcionais: peso e altura (que são utilizados para funcionalidades relacionadas ao consumo diário de calorias individual)</li>
                    </ul>
                  </div>
                  <p>2.2 Uso dos Dados</p>
                  <p>Os dados coletados são armazenados em um banco de dados privado e utilizados apenas para cálculos e geração de gráficos para uso próprio do usuário. Eles também podem ser usados para pesquisas internas da Lorie.</p>
                </div>
                <div className="landing__privacy__paragraph">
                  <h3>3 . Pagamentos e Assinaturas</h3>
                  <p>3.1 Conteúdo do Usuário</p>
                  <p>A Lorie não possui funcionalidades de pagamentos ou assinaturas.</p>
                  <div className="landing__privacy__bulletPoints">
                  </div>
                  <p>3.2 Licença Concedida</p>
                  <p>Os usuários concedem à Lorie a licença para utilizar os dados de suas refeições para fins de cálculos e pesquisas internas.</p>
                </div>
                <div className="landing__privacy__paragraph">
                  <h3>4 . Regras e Condutas</h3>
                  <p>Embora a Lorie não tenha regras de conduta específicas, recomendamos fortemente que os usuários:</p>
                  <div className="landing__privacy__bulletPoints">
                    <ul>
                      <li>Não compartilhem suas senhas com terceiros.</li>
                      <li>Relatem  quaisquer bugs ou falhas encontradas através dos canais de contato disponíveis, evitando a exploração desses problemas.</li>
                    </ul>
                  </div>
                </div>
                <div className="landing__privacy__paragraph">
                  <h3>5 . Limitação de Responsabilidade</h3>
                  <p>5.1 Responsabilidade</p>
                  <p>O aplicativo Lorie não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, especiais, consequentes ou exemplares, incluindo, mas não se limitando a, perda de dados, falhas no sistema, ou problemas de saúde decorrentes do uso da aplicação ou das recomendações de consumo de calorias fornecidas.</p>
                  <div className="landing__privacy__bulletPoints">
                  </div>
                  <p>5.2 Serviços de Terceiros</p>
                  <p>O aplicativo Lorie pode integrar serviços de terceiros para oferecer funcionalidades adicionais aos usuários. A Lorie não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, especiais, consequentes ou exemplares decorrentes da utilização desses serviços de terceiros, incluindo, mas não se limitando a, falhas no serviço, interrupções, atrasos, erros ou omissões, ou qualquer conteúdo perdido, corrompido ou não entregue. Os usuários são encorajados a revisar e concordar com os termos de serviço e políticas de privacidade dos serviços de terceiros antes de utilizá-los com o aplicativo Lorie.</p>
                </div>
                <div className="landing__privacy__paragraph">
                  <h3>6 . Alterações nos Termos de Uso</h3>
                  <p>6.1 Notificação de Alterações</p>
                  <p>Os usuários serão notificados sobre qualquer alteração nos termos de uso através do aplicativo. Na próxima vez que fizerem login, será solicitado que aceitem os novos termos por meio de uma modal que aparecerá.</p>
                  <div className="landing__privacy__bulletPoints">
                  </div>
                  <p>6.2 Aceitação dos Novos Termos</p>
                  <p>Para continuar utilizando a Lorie, os usuários devem ler e aceitar os novos termos clicando no botão de aceite. Caso não aceitem, o uso das funcionalidades da Lorie será bloqueado.</p>
                </div>
                <div className="landing__privacy__paragraph">
                  <h3>7 . Contato e Suporte</h3>
                  <p>Para suporte, questões relacionadas aos termos de uso ou propostas de negócio, os usuários podem entrar em contato com a Lorie através do e-mail: contact@lorie.app.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="landing__footer">
            <div className="landing__footer__logoSection">
              <a href="javascript:;" className="landing__footer__logo">
                <img src={TextLogo} alt="logo" />
              </a>
              <div className="landing__footer__copyright">
                <p>© 2024 Lorie. Todos os direitos reservados.</p>
              </div>
            </div>
            <a href="javascript:;" className="landing__footer__termsAndPrivacy">
              <p>Termos e privacidade</p>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default Privacy;

Privacy.propTypes = {};
