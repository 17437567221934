import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Logo from "../../assets/imgs/white-logo.png";
import GoogleIcon from "../../assets/imgs/google-icon.svg";
import TextLogo from "../../assets/imgs/text-logo.svg";

import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import loginService from "../../services/login-service";
import { useLocation } from "react-router-dom";
import { getAPIUrl } from "../../config";
import TextInput from "../../components/TextInput/TextInput";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import { GlobalContext } from "../GlobalContext";

const apiURL = getAPIUrl();

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginWasInvalid, setLoginWasInvalid] = useState(false);
  const [googleLoginWasInvalid, setGoogleLoginWasInvalid] = useState(false);
  const { setIsLoading } = useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    document.title = "Conecte-se";
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const googleLoginWasInvalid = searchParams.get("failedGoogleAuth");

    if (googleLoginWasInvalid) {
      setGoogleLoginWasInvalid(true);
    }

    if (token) {
      loginService.saveTokenForGoogleAuthentication(token).then(() => {
        window.location.assign("/home");
      });
    }

    const fetchCurrentUser = async () => {
      const userFound = await loginService.getCurrentUser();

      if (userFound) {
        window.location.assign("/home");
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };

    fetchCurrentUser();
  }, [loginService]);

  const handleLogin = async () => {
    try {
      await loginService.login(email, password);
      setIsLoading(true);
      window.location.assign("/home");
    } catch (err) {
      setLoginWasInvalid(true);
    }
  };

  useEffect(() => {
    document.title = "Conecte-se";
  }, []);

  return (
    <div className="login">
      <div className="login__content">
        <div className="login__logo">
          <img src={TextLogo} alt="Logo" />
        </div>
        <div className="login__forms">
          <div className="login__forms__email">
            <TextInput
              type="email"
              label="E-mail"
              placeholder="E-mail"
              value={email}
              onChangeText={(text) => {
                setEmail(text);
                setLoginWasInvalid(false);
                setGoogleLoginWasInvalid(false);
              }}
            />
          </div>
          <TextInput
            label="Senha"
            placeholder="Senha"
            type="password"
            value={password}
            onChangeText={(text) => {
              setPassword(text);
              setLoginWasInvalid(false);
              setGoogleLoginWasInvalid(false);
            }}
            secureTextEntry={true}
          />
          {loginWasInvalid && (
            <p className="login__forms__password__error">
              Usuário ou senha inválidos
            </p>
          )}
          {googleLoginWasInvalid && (
            <p className="login__forms__password__error">
              Erro ao realizar login com Google
            </p>
          )}
        </div>
        <div className="login__forget-password">
          <a href="#">Esqueceu sua senha?</a>
        </div>
        <div className="login__action">
          <DefaultButton
            title="Entrar"
            onClick={handleLogin}
          />
        </div>
        <div className="login__social">
          <div className="login__social__title">
            <p>Ou</p>
          </div>
          <div className="login__social__google">
            <Button
              onClick={() => {
                window.location.href = `${apiURL}/oauth2/authorization/google`;
              }}
            >
              <img src={GoogleIcon} alt="Google" />
              <p>Continuar com Google</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

Login.propTypes = {};
