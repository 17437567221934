import "./ConfirmationModal.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import { Button, Modal } from "react-bootstrap";
import InfoIcon from "../../assets/imgs/info-icon.svg";
import ForbiddenIcon from "../../assets/imgs/forbidden-icon.svg";
import DefaultButton from "../DefaultButton/DefaultButton";

const ConfirmationModal = ({
  title,
  description,
  onConfirm,
  onCancel,
  show,
  setShow,
}) => (
  <>
    <Modal show={show} onHide={() => setShow(false)}>
      <div className="confirmation-modal">
        <div className="confirmation-modal__icon">
          <img src={ForbiddenIcon} alt="Forbidden Icon" />
        </div>
        <div className="confirmation-modal__title">
          <h4>{title}</h4>
        </div>
        <div className="confirmation-modal__description">
          <p>{description}</p>
        </div>
        <div className="confirmation-modal__actions">
          <DefaultButton
            title="Excluir"
            onClick={onConfirm}
          />
          <DefaultButton
            title="Cancelar"
            onClick={onCancel}
            type="secondary"
          />
        </div>
      </div>
    </Modal>
  </>
);

ConfirmationModal.defaultProps = {
  title: "Confirmação",
  description: "Tem certeza que deseja excluir esse item?",
  onConfirm: () => { },
  onCancel: () => { },
  show: false,
};

export default ConfirmationModal;
