import "./CreateFoodstuff.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import ArrowLeftIcon from "../../assets/imgs/arrow-left-icon.svg";
import brandService from "../../services/brand-service";
import foodstuffService from "../../services/foodstuff-service";
import { useLocation, useParams } from "react-router-dom";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import TextInput from "../../components/TextInput/TextInput";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import { GlobalContext } from "../GlobalContext";
import ImageSelector from "../../components/ImageSelector/ImageSelector";
import CreateBrandModal from "../../components/CreateBrandModal/CreateBrandModal";
import DefaultDropdown from "../../components/DefaultDropdown/DefaultDropdown";
import WeightConverter from "../../components/WeightConverter/WeightConverter";

const foodGroups = [
  {
    name: "Frutas",
    value: "FRUITS",
  },
  {
    name: "Vegetais",
    value: "VEGETABLES",
  },
  {
    name: "Carnes",
    value: "MEATS",
  },
  {
    name: "Laticínios",
    value: "DAIRY",
  },
  {
    name: "Grãos",
    value: "GRAINS",
  },
  {
    name: "Legumes",
    value: "LEGUMES",
  },
  {
    name: "Oleos",
    value: "OILS",
  },
  {
    name: "Doces",
    value: "SWEETS",
  },
  {
    name: "Bebidas",
    value: "BEVERAGES",
  },
  {
    name: "Água",
    value: "WATER",
  },
  {
    name: "Outros",
    value: "OTHERS",
  },
];
const unitsOfMeasurement = [
  {
    name: "Unidades",
    value: "UNITS",
  },
  {
    name: "Gramas",
    value: "GRAMS",
  },
  {
    name: "Kilogramas",
    value: "KILOGRAMS",
  },
  {
    name: "Litros",
    value: "Liters",
  },
  {
    name: "Mililitros",
    value: "MILLILITERS",
  },
];

const CreateFoodstuff = (props) => {
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedUnitOfMeasurement, setSelectedUnitOfMeasuremnet] = useState();
  const [typedBrand, setTypedBrand] = useState("");
  const [foodstuff, setFoodstuff] = useState({});
  const [nutritionInformation, setNutritionInformation] = useState({});
  const [params, setParams] = useState(
    new URLSearchParams(useLocation().search)
  );
  const [createdFoodstuff, setCreatedFoodstuff] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [showCreateBrandModal, setShowCreateBrandModal] = useState(false);
  const [showWeightConverter, setShowWeightConverter] = useState(false);
  const { setSelectedScreen, setIsLoading } = useContext(GlobalContext);

  useEffect(() => {
    document.title = "Novo alimento";
  }, []);

  const createdFoodDescription = <>
    <p>O alimento <b>{foodstuff.name}</b> foi criado com sucesso. Você pode visualizá-lo ou voltar à listagem.</p>
  </>

  const fetchDraftFoodstuff = useCallback(async () => {
    const draftFoodstuffId = params.get("draftFoodstuffId");
    if (!draftFoodstuffId) {
      return;
    }

    try {
      const foodstuffResponse = await foodstuffService.getFoodstuffById(
        draftFoodstuffId
      );
      setFoodstuff((prevState) => ({
        ...prevState,
        brand_id: foodstuffResponse?.brand?.id,
        name: foodstuffResponse?.name,
        group: foodstuffResponse?.group,
        unit_of_measurement: foodstuffResponse?.unit_of_measurement,
        baseline_weight: foodstuffResponse?.baseline_weight || '',
        photo_id: foodstuffResponse?.photo_id,
      }));
      setTypedBrand(foodstuffResponse?.brand?.name || "");
      setSelectedBrand(foodstuffResponse.brand ?? undefined);
      setSelectedUnitOfMeasuremnet(
        unitsOfMeasurement.find(
          (unit) => unit.value === foodstuffResponse.unit_of_measurement
        )
      );
      setSelectedGroup(
        foodGroups.find((group) => group.value === foodstuffResponse.group)
      );
      setSelectedPhoto({
        id: foodstuffResponse?.photo_id,
        url: foodstuffResponse?.photo_url,
      });

      setNutritionInformation(foodstuffResponse.nutrition_information);
    } catch {
      console.log("erro ao buscar alimentos");
    }
  }, []);

  const fetchBrands = async (name) => {
    const brandsResponse = await brandService.getBrands(0, 5, name);
    setBrands(brandsResponse.content);
  };

  useEffect(() => {
    setSelectedScreen("create-foodstuff");
  }, []);

  const handleCreateFoodstuff = async () => {
    setIsLoading(true);
    const body = { ...foodstuff, nutrition_information: nutritionInformation, photo_id: selectedPhoto?.id };
    try {
      const response = await foodstuffService.createFoodstuff(body);
      setCreatedFoodstuff(response);
      setShowSuccessModal(true);
    } catch (err) {
      console.log("error while creating foodstuff", err);
    } finally {
      setIsLoading(false);
    }
  };

  const nutritionInfoWasFilled = () =>
    nutritionInformation.calories &&
    nutritionInformation.proteins &&
    nutritionInformation.carbohydrates &&
    nutritionInformation.sodium &&
    nutritionInformation.fiber &&
    nutritionInformation.fats;

  const foodstuffFieldsWereFilled = () =>
    foodstuff.name &&
    foodstuff.group &&
    foodstuff.unit_of_measurement &&
    foodstuff.baseline_weight;

  const allFieldsWereFilled = () =>
    nutritionInfoWasFilled() && foodstuffFieldsWereFilled();

  const handleSelectPhoto = (photo) => {
    setSelectedPhoto(photo);
  }

  const handleConvertWeightToUnit = (unitWeight) => {
    const proportion = unitWeight / foodstuff.baseline_weight;

    setNutritionInformation({
      ...nutritionInformation,
      calories: (nutritionInformation.calories * proportion).toFixed(1),
      proteins: (nutritionInformation.proteins * proportion).toFixed(1),
      carbohydrates: (nutritionInformation.carbohydrates * proportion).toFixed(1),
      sodium: (nutritionInformation.sodium * proportion).toFixed(1),
      fiber: (nutritionInformation.fiber * proportion).toFixed(1),
      fats: (nutritionInformation.fats * proportion).toFixed(1),
    });

    setFoodstuff({
      ...foodstuff,
      baseline_weight: unitWeight,
    });

    setSelectedUnitOfMeasuremnet(unitsOfMeasurement.find((unit) => unit.value === "UNITS"));
  }

  useEffect(() => {
    Promise.resolve(fetchDraftFoodstuff());
  }, [params]);

  return (
    <>
      <div className="create-foodstuff">
        <div className="new-foodstuff">
          <div className="top-menu__title">
            <a href="javascript:;" onClick={() => {
              window.location.href = '/foodstuffs';
            }}>
              <div className="top-menu__back-screen">
                <img src={ArrowLeftIcon} alt="Arrow Left Icon" />
              </div>
              <h1>Novo alimento</h1>
            </a>
          </div>
          <div className="new-foodstuff__content">
            <div className="new-foodstuff__basic-information">
              <div className="new-foodstuff__basic-information__content">
                <div className="new-foodstuff__basic-information__content__select-image">
                  <ImageSelector initialPhotoUrl={selectedPhoto?.url} onSelect={(photo) => handleSelectPhoto(photo)} />
                </div>
                <div className="new-foodstuff__basic-info__right">
                  <div className="new-foodstuff__basic-info__right__first-line">
                    <div className="new-foodstuff__basic-info__content__name">
                      <TextInput
                        label="Nome"
                        placeholder="Nome"
                        value={foodstuff?.name}
                        onChangeText={(text) => setFoodstuff({ ...foodstuff, name: text })}
                      />
                    </div>
                  </div>
                  <div className="new-foodstuff__basic-info__right__second-line">
                    <div className="new-foodstuff__basic-info__content__group">
                      <DefaultDropdown
                        placeholder="Grupo alimentar"
                        value={selectedGroup?.name}
                        options={foodGroups.map((group) => group.name)}
                        onSelect={(groupName) => {
                          const groupFound = foodGroups.find((group) => group.name === groupName);
                          setSelectedGroup(groupFound);
                          setFoodstuff({ ...foodstuff, group: groupFound.value });
                        }}
                      />
                    </div>
                    <div className="new-foodstuff__basic-info__content__measurement">
                      <DefaultDropdown
                        placeholder="Forma de medida"
                        value={selectedUnitOfMeasurement?.name}
                        options={unitsOfMeasurement.map((unit) => unit.name)}
                        onSelect={(unitName) => {
                          const unitFound = unitsOfMeasurement.find((unit) => unit.name === unitName);
                          setSelectedUnitOfMeasuremnet(unitFound);
                          setFoodstuff({ ...foodstuff, unit_of_measurement: unitFound.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="new-foodstuff__basic-info__right__third-line">
                    <div className="new-foodstuff__basic-info__weight">
                      <TextInput
                        label="Peso base (g, ml)"
                        placeholder="Peso base (g, ml)"
                        value={`${foodstuff?.baseline_weight || ''}`}
                        onChangeText={(text) =>
                          setFoodstuff({ ...foodstuff, baseline_weight: text })
                        }
                      />
                    </div>
                    <div className="new-foodstuff__basic-info__barcode">
                      <TextInput
                        label="Código de barras"
                        placeholder="Código de barras"
                        value={`${foodstuff?.barcode || ''}`}
                        onChangeText={(text) =>
                          setFoodstuff({ ...foodstuff, barcode: text })
                        }
                      />
                    </div>
                    <div className="new-foodstuff__basic-info__brand">
                      <DefaultDropdown
                        placeholder="Marca"
                        value={selectedBrand?.name}
                        options={brands.map((brand) => brand.name)}
                        onSelect={(brandName) => {
                          const brandFound = brands.find((brand) => brand.name === brandName);
                          setSelectedBrand(brandFound);
                          setFoodstuff({ ...foodstuff, brand_id: brandFound.id });
                        }}
                        searchPlaceholder="Buscar marca"
                        onSearch={fetchBrands}
                        onClickCreate={() => setShowCreateBrandModal(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-foodstuff__nutritional-info">
              <div className="nutritional-info__title">
                <h3>Informações nutricionais</h3>
              </div>
              <div className="nutritional-info__content">
                <div className="nutritional-info__content__calories">
                  <TextInput
                    label="Calorias"
                    placeholder="Calorias"
                    value={`${nutritionInformation?.calories ?? ''}`}
                    type="number"
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, calories: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__proteins">
                  <TextInput
                    label="Proteínas (g)"
                    placeholder="Proteínas (g)"
                    value={`${nutritionInformation?.proteins ?? ''}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, proteins: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__carbohydrates">
                  <TextInput
                    label="Carboidratos (g)"
                    placeholder="Carboidratos (g)"
                    value={`${nutritionInformation?.carbohydrates ?? ''}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, carbohydrates: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__sodium">
                  <TextInput
                    label="Sódio (mg)"
                    placeholder="Sódio (mg)"
                    value={`${nutritionInformation?.sodium ?? ''}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, sodium: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__fibers">
                  <TextInput
                    label="Fibras (g)"
                    placeholder="Fibras (g)"
                    value={`${nutritionInformation?.fiber ?? ''}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, fiber: text })
                    }
                  />
                </div>
                <div className="nutritional-info__content__saturated-fats">
                  <TextInput
                    label="Gordura totais (g)"
                    placeholder="Gordura totais (g)"
                    value={`${nutritionInformation?.fats ?? ''}`}
                    onChangeText={(text) =>
                      setNutritionInformation({ ...nutritionInformation, fats: text })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="new-foodstuff__footer">
              {selectedUnitOfMeasurement && selectedUnitOfMeasurement?.value !== "UNITS" && (
                <div className="new-foodstuff__footer__convert">
                  <DefaultButton
                    title="Converter para unidade"
                    onClick={() => setShowWeightConverter(true)}
                    disabled={
                      !foodstuff?.baseline_weight ||
                      !nutritionInformation?.calories ||
                      !nutritionInformation?.proteins ||
                      !nutritionInformation?.carbohydrates ||
                      !nutritionInformation?.sodium ||
                      !nutritionInformation?.fiber ||
                      !nutritionInformation?.fats
                    }
                    type="secondary"
                  />
                </div>
              )}
              <div className="new-foodstuff__create-button">
                <DefaultButton
                  title="Adicionar alimento"
                  onClick={handleCreateFoodstuff}
                  disabled={!allFieldsWereFilled()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        title="Alimento criado com sucesso!"
        description={createdFoodDescription}
        show={showSuccessModal}
        confirmTitle="Exibir alimento"
        backTitle="Criar outro alimento"
        onConfirm={() => window.location.href = `/foodstuffs/${createdFoodstuff?.id}/editing`}
        onBack={() => window.location.href = "/create-foodstuff"}
        onExit={() => window.location.href = "/foodstuffs"}
      />
      <CreateBrandModal
        showModal={showCreateBrandModal}
        onClose={() => setShowCreateBrandModal(false)}
      />
      <WeightConverter
        showModal={showWeightConverter}
        onClose={() => setShowWeightConverter(false)}
        onConvert={(unitWeight) => handleConvertWeightToUnit(unitWeight)}
      />
    </>
  );
};

export default CreateFoodstuff;
