import "./SuccessModal.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import { Modal } from "react-bootstrap";
import SuccessIcon from "../../assets/imgs/success-icon.svg";
import DefaultButton from "../DefaultButton/DefaultButton";

const SuccessModal = ({
  title,
  description,
  confirmTitle,
  backTitle,
  onConfirm,
  onBack,
  onExit,
  show,
}) => (
  <>
    <Modal show={show} onHide={onExit}>
      <div className="confirmation-modal">
        <div className="confirmation-modal__icon">
          <img src={SuccessIcon} alt="Forbidden Icon" />
        </div>
        <div className="confirmation-modal__title">
          <h4>{title}</h4>
        </div>
        <div className="confirmation-modal__description">
          <p>{description}</p>
        </div>
        <div className="confirmation-modal__actions">
          <DefaultButton
            title={confirmTitle}
            onClick={onConfirm}
          />
          {backTitle && (
            <DefaultButton
              title={backTitle}
              onClick={onBack}
              type="secondary"
            />
          )}
        </div>
      </div>
    </Modal>
  </>
);

SuccessModal.defaultProps = {
  title: "Sucesso!",
  description: "Item cadastrado com sucesso!",
  confirmTitle: "Exibir item",
  backTitle: "",
  onConfirm: () => { },
  onBack: () => { },
  onExit: () => { },
  show: false,
}

export default SuccessModal;
