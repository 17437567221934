import "./DefaultDropdown.css";

import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Dropdown, FormGroup } from "react-bootstrap";
import DefaultButton from "../DefaultButton/DefaultButton";
import TextInput from "../TextInput/TextInput";

const DefaultDropdown = ({
  placeholder,
  value,
  options,
  onSelect,
  searchPlaceholder,
  onSearch,
  onClickCreate,
}) => {
  const [searchedValue, setSearchedValue] = React.useState("");

  useEffect(() => {
    setSearchedValue(value);
  }, [value]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className={value ? "dropdown__title--selected" : "dropdown__title"}
      >
        {value ?? placeholder}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {searchPlaceholder && (
          <div className="new-foodstuff__brand__searchSection">
            <div className="new-foodstuff__brand__searchSection__input">
              <FormGroup as={Col}>
                <TextInput
                  type="text"
                  placeholder={searchPlaceholder}
                  value={searchedValue ?? (value || "")}
                  onChangeText={(text) => {
                    setSearchedValue(text);
                    onSearch(text);
                  }}
                  size="small"
                />
              </FormGroup>
            </div>
            {onClickCreate && (
              <div className="new-foodstuff__brand__searchSection__button">
                <DefaultButton
                  title="+"
                  onClick={() => onClickCreate()}
                  size="small"
                />
              </div>
            )}
          </div>
        )}
        {options.map((option) => (
          <Dropdown.Item
            key={option}
            onClick={() => onSelect(option)}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

DefaultDropdown.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
};

export default DefaultDropdown;
