import "./TopMenu.css";
import React, { useContext, useEffect, useState } from "react";

import DefaultUserIcon from "../../assets/imgs/default-user-icon.png";
import FoodIcon from "../../assets/imgs/food-icon.svg";
import FoodSelectedIcon from "../../assets/imgs/food-selected-icon.svg";
import DashboardIcon from "../../assets/imgs/dashboard-icon.svg";
import DashboardSelectedIcon from "../../assets/imgs/dashboard-selected-icon.svg";
import TextLogo from "../../assets/imgs/text-logo.svg";

import loginService from "../../services/login-service";
import { Dropdown } from "react-bootstrap";

const TopMenu = () => {
  const [currentUser, setCurrentUser] = useState();
  const [openOptionsDropdown, setOpenOptionsDropdown] = useState(false);

  const currentPage = window.location.pathname.split("/")[1];

  const additionalOptions = [
    {
      title: "Logout",
      onClick: () => {
        loginService.logout();
        window.location.href = "/login";
      },
    }
  ]

  useEffect(() => {
    async function fetchCurrentUser() {
      const userFound = await loginService.getCurrentUser();
      setCurrentUser(userFound);
    };

    fetchCurrentUser();
  }, [])

  return (
    <div className="top-menu__shadow">
      <div className="top-menu">
        <a className="top-menu__logo" href="/home">
          <img src={TextLogo} alt="Text Logo" />
        </a>
        <div className="top-menu__items">
          <a href="/foodstuffs">
            <div
              className={`top-menu__items__item ${currentPage === "create-foodstuff" ||
                currentPage.startsWith("foodstuffs") && "top-menu__items__item--chosen"}`}
            >
              <div className="top-menu__items__item__icon">
                {currentPage === "create-foodstuff" || currentPage.startsWith("foodstuffs") ? (
                  <img src={FoodSelectedIcon} alt="Dashboard selected icon" />
                ) : (
                  <img src={FoodIcon} alt="Food icon" />
                )}
              </div>
            </div>
          </a>
          <a href="/home">
            <div
              className={`top-menu__items__item ${currentPage === "home" && "top-menu__items__item--chosen"}`}
            >
              <div className="top-menu__items__item__icon">
                {currentPage === "home" ? (
                  <img src={DashboardSelectedIcon} alt="Dashboard selected icon" />
                ) : (
                  <img src={DashboardIcon} alt="Dashboard icon" />
                )}
              </div>
            </div>
          </a>
        </div>
        <div className="top-menu__user">
          <a className="top-menu__user__icon" href="javascript:;" onClick={() => {
            setOpenOptionsDropdown(prevState => !prevState);
          }}>
            <img src={currentUser?.photo_url ?? DefaultUserIcon} alt="User Icon" />
          </a>
          <Dropdown
            show={openOptionsDropdown}
            onToggle={() => setOpenOptionsDropdown(prevState => !prevState)}
          >
            <Dropdown.Menu bsPrefix="dropdown-menu top-menu__additional-options__dropdown">
              {additionalOptions?.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={item.onClick}
                  className={`top-menu__additional-options__dropdown__item ${item.className || ''}`}
                >
                  {item.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
