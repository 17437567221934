import "./WeightConverter.css";

import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import TextInput from "../TextInput/TextInput";
import SuccessModal from "../SuccessModal/SuccessModal";
import ErrorModal from "../ErrorModal/ErrorModal";

import DefaultButton from "../DefaultButton/DefaultButton";

import { GlobalContext } from "../../pages/GlobalContext";

const WeightConverter = ({ 
  showModal,
  onClose,
  onConvert,
 }) => {
  const [openModal, setOpenModal] = React.useState(true);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [successTitle, setSuccessTitle] = React.useState("");
  const [successDescription, setSuccessDescription] = React.useState("");
  const [showeErrorModal, setShowErrorModal] = React.useState(false);
  const [errorTitle, setErrorTitle] = React.useState("");
  const [errorDescription, setErrorDescription] = React.useState("");
  const [weight, setWeight] = React.useState("");
  const { setIsLoading } = React.useContext(GlobalContext);

  const handleCloseModal = () => {
    setOpenModal(false);
    setWeight("");
    onClose();
  }

  useEffect(() => {
    setOpenModal(showModal);
  }, [showModal]);

  return (
    <>
      <div className="create-brand__modal">
        <Modal show={openModal} onHide={handleCloseModal} onExit={handleCloseModal} >
          <div className="create-brand__modal__header">
            <h4>Conversão para unidade</h4>
          </div>
          <div className="create-brand__modal__content">
            <div className="create-brand__modal__search">
              <TextInput
                label="Peso da unidade"
                placeholder="Peso da unidade"
                onChangeText={(text) => setWeight(text)}
              />
            </div>
            <div className="create-brand__modal__buttons">
              <div className="create-brand__modal__button">
                <DefaultButton
                  title="Voltar"
                  onClick={handleCloseModal}
                  type="secondary"
                  size="medium"
                />
              </div>
              <div className="create-brand__modal__button">
                <DefaultButton
                  title="Converter"
                  onClick={() => {
                    onConvert(weight);
                    handleCloseModal();
                  }}
                  disabled={!weight}
                  type="primary"
                  size="medium"
                />
              </div>
            </div>
          </div>
        </Modal>
      </div >
      <SuccessModal
        title={successTitle}
        description={successDescription}
        show={showSuccessModal}
        confirmTitle="Voltar"
        onConfirm={() => setShowSuccessModal(false)}
        onBack={() => setShowSuccessModal(false)}
        onExit={() => setShowSuccessModal(false)}
      />
      <ErrorModal
        title={errorTitle}
        description={errorDescription}
        show={showeErrorModal}
        confirmTitle="Voltar"
        onConfirm={() => setShowErrorModal(false)}
        onExit={() => setShowErrorModal(false)}
        onBack={() => setShowErrorModal(false)}
      />
    </>
  );
};

WeightConverter.propTypes = {
  initialPhotoUrl: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default WeightConverter;
