import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const brandService = {
  async getBrands(page, size, name) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get("/v1/brands", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          name,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar lista de marcas");
    }
  },

  async createBrand(name) {
    const token = localStorage.getItem("token");

    const response = await api.post(`/v1/brands`, {
      name,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },
};

export default brandService;