import "./Foodstuffs.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";

import DefaultFoodstuffIcon from "../../assets/imgs/default-foodstuff-icon.svg";
import FilterIcon from "../../assets/imgs/filter-icon.svg";
import CheckedIcon from "../../assets/imgs/checked-icon.svg";

import foodstuffService from "../../services/foodstuff-service";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import TextInput from "../../components/TextInput/TextInput";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import { GlobalContext } from "../GlobalContext";
import { useLocation } from "react-router-dom";
import Options from "../../components/Options/Options";
import NumericPagination from "../../components/NumericPagination/NumericPagination";
import { Dropdown } from "react-bootstrap";

const Foodstuffs = () => {
  const [foodstuffsResponse, setFoodstuffsResponse] = useState({});
  const [foodstuffs, setFoodstuffs] = useState([]);
  const [currentFoodstuffsPage, setCurrentFoodstuffsPage] = useState();
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [foodstuffOnDelete, setFoodstuffOnDelete] = useState();
  const [deleteFoodstuffError, setDeleteFoodstuffError] = useState();
  const [searchedFoodstuffName, setSearchedFoodstuffName] = useState("");
  const [searchedFoodstuffBrand, setSearchedFoodstuffBrand] = useState("");
  const [withBarcode, setWithBarcode] = useState();
  const [withBrand, setWithBrand] = useState();
  const searchParams = new URLSearchParams(useLocation().search);
  const foodstuffPageParam = searchParams?.get("currentPage");
  const withBarcodeParam = searchParams?.get("withBarcode");
  const withBrandParam = searchParams?.get("withBrand");
  const { setSelectedScreen, setIsLoading } = useContext(GlobalContext);

  const [openOptionsDropdown, setOpenOptionsDropdown] = useState(false);

  const fetchFoodstuffs = useCallback(
    async (name, page, withBarcodeParam, withBrandParam) => {
      if (page < 0) {
        fetchFoodstuffs(name, 0);
        return;
      }

      setIsLoading(true);
      setCurrentFoodstuffsPage(page + 1);
      try {
        const foodstuffsResponse = await foodstuffService.getFoodstuffs(
          page,
          7,
          name,
          withBarcodeParam !== undefined ? withBarcodeParam : withBarcode,
          withBrandParam !== undefined ? withBrandParam : withBrand,
        );
        setFoodstuffsResponse(foodstuffsResponse);
        setFoodstuffs(foodstuffsResponse.content);

        if (page > foodstuffsResponse.total_pages) {
          fetchFoodstuffs(name, foodstuffsResponse.total_pages - 1);
        }
      } catch (error) {
        console.log("Erro ao buscar alimentos", error);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFoodstuffsPage, withBarcode, withBrand]
  );

  const handleDeleteFoodstuff = async () => {
    try {
      await foodstuffService.deleteFoodstuff(foodstuffOnDelete.id);
      setOpenDeleteConfirmModal(false);
      setFoodstuffOnDelete();
      await fetchFoodstuffs("", 0);
    } catch (error) {
      setDeleteFoodstuffError(error);
      console.log("Erro ao deletar alimento", error);
    }
  };

  const deleteDescription = (
    <>
      Ao excluir o alimento <b>{foodstuffOnDelete?.name}</b>, todas as
      informações relacionadas a ele serão perdidas.
    </>
  );

  const formatFoodstuffName = (name) => {
    return name.length > 20 ? `${name.substring(0, 20)}...` : name;
  }

  const getUnitOfMeasurement = (unit) => {
    switch (unit) {
      case "GRAMS":
        return "g";
      case "MILLILITERS":
        return "ml";
      case "LITERS":
        return "l";
      default:
        return "g";
    }
  }

  const getParams = () => {
    return `?currentPage=${currentFoodstuffsPage}${withBarcode !== undefined ? `&withBarcode=${withBarcode}` : ""}${withBrand !== undefined ? `&withBrand=${withBrand}` : ""}`;
  };

  useEffect(() => {
    document.title = "Alimentos";
    setSelectedScreen("foodstuffs");
    const pageFromParam = foodstuffPageParam ? Number(foodstuffPageParam) : 0;
    const withBarcodeFromParam = withBarcodeParam ? withBarcodeParam === "true" : undefined;
    const withBrandFromParam = withBrandParam ? withBrandParam === "true" : undefined;

    setCurrentFoodstuffsPage(pageFromParam ? Number(pageFromParam) : 1);
    setWithBarcode(withBarcodeFromParam);
    setWithBrand(withBrandFromParam);

    async function fetchData() {
      await Promise.all([fetchFoodstuffs("", pageFromParam, withBarcodeFromParam, withBrandFromParam)]);
    }

    fetchData();
  }, []);

  useEffect(() => {
    const pageFromParam = foodstuffPageParam ? Number(foodstuffPageParam) : 1;
    if (pageFromParam) {
      setCurrentFoodstuffsPage(Number(pageFromParam));
    }
  }, [foodstuffPageParam]);

  return (
    <>
      <div className="right-side">
        <div className="right-side__content">
          <div className="right-side__content__header">
            <h1>Alimentos</h1>
          </div>
          <div className="foodstuffs">
            <div className="foodstuffs__content">
              <div className="foodstuffs__search">
                <div className="foodstuffs__search__name">
                  <TextInput
                    label="Nome, marca..."
                    placeholder="Nome, marca..."
                    onChangeText={(e) => {
                      setSearchedFoodstuffName(e);
                      fetchFoodstuffs(e, 0);
                    }}
                  />
                </div>
                <a className={`foodstuff__search__filter ${openOptionsDropdown && 'foodstuff__search__filter--pressed'}`} href="javascript:;" onClick={() => setOpenOptionsDropdown(prevState => !prevState)}>
                  <img src={FilterIcon} alt="Filter icon" />
                </a>
                <Dropdown
                  show={openOptionsDropdown}
                  onToggle={() => setOpenOptionsDropdown(prevState => !prevState)}
                >
                  <Dropdown.Menu bsPrefix="dropdown-menu foodstuff__search__filter__dropdown">
                    <Dropdown.Header>
                      Filtro Avançado
                    </Dropdown.Header>
                    <div className="foodstuff__search__filter__dropdown__options">
                      <div className="foodstuff__search__filter__dropdown__option">
                        <a className="foodstuff__search__filter__dropdown__option__checkbox" href="javascript:;" onClick={() => {
                          const withBarcodeParam = withBarcode !== undefined ? withBarcode : false;
                          setWithBarcode(() => {
                            fetchFoodstuffs(searchedFoodstuffName, currentFoodstuffsPage - 1, !withBarcodeParam, withBrand);
                            return !withBarcodeParam;
                          })
                        }}>
                          <div className={`foodstuff__search__filter__dropdown__option__checkbox__box ${withBarcode && 'checkbox--checked'}`} >
                            <img src={CheckedIcon} alt="Checked icon" />
                          </div>
                          <div className="foodstuff__search__filter__dropdown__option__checkbox__label">Possui código de barras</div>
                        </a>
                      </div>
                      <div className="foodstuff__search__filter__dropdown__option">
                        <a className="foodstuff__search__filter__dropdown__option__checkbox" href="javascript:;" onClick={() => {
                          const withBrandParam = withBrand !== undefined ? withBrand : false;
                          setWithBrand(() => {
                            fetchFoodstuffs(searchedFoodstuffName, currentFoodstuffsPage - 1, withBarcode, !withBrandParam);
                            return !withBrandParam;
                          })
                        }}>
                          <div className={`foodstuff__search__filter__dropdown__option__checkbox__box ${withBrand && 'checkbox--checked'}`} >
                            <img src={CheckedIcon} alt="Checked icon" />
                          </div>
                          <div className="foodstuff__search__filter__dropdown__option__checkbox__label">Possui marca</div>
                        </a>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="foodstuffs__button">
                  <DefaultButton
                    title="Novo alimento"
                    onClick={() => window.location.href = "/create-foodstuff"}
                  />
                </div>
              </div>
              <div className="foodstuffs__list">
                <div className="foodstuffs__list__header">
                  <div className="foodstuffs__list__header__image">
                    Imagem
                  </div>
                  <div className="foodstuffs__list__header__name">Nome</div>
                  <div className="foodstuffs__list__header__type">Tipo</div>
                  <div className="foodstuffs__list__header__measurement">
                    Medida
                  </div>
                  <div className="foodstuffs__list__header__brand">Marca</div>
                  <div className="foodstuffs__list__header__weight">Peso base</div>
                  <div className="foodstuffs__list__header__actions" />
                </div>
                <div className="foodstuffs__list__items">
                  {foodstuffs.map((foodstuff) => (
                    <div className="foodstuffs__list__item">
                      <div className="foodstuffs__list__item__image">
                        <img src={foodstuff.photo_url ?? DefaultFoodstuffIcon} alt="Banana icon" />
                      </div>
                      <div className="foodstuffs__list__item__name">
                        {formatFoodstuffName(foodstuff.name)}
                      </div>
                      <div className="foodstuffs__list__item__type">
                        {foodstuff.group}
                      </div>
                      <div className="foodstuffs__list__item__measurement">
                        {foodstuff.unit_of_measurement}
                      </div>
                      <div
                        className={`foodstuffs__list__item__brand ${foodstuff?.brand?.name ? '' : 'foodstuffs__list__item__brand--not-appliable'}`}
                      >
                        {`${foodstuff?.brand?.name ?? "Não aplicável"}`}
                      </div>
                      <div
                        className={`foodstuffs__list__item__weight`}
                      >
                        {`${foodstuff?.baseline_weight} ${getUnitOfMeasurement(foodstuff.unit_of_measurement)}`}
                      </div>
                      <div className="foodstuffs__list__item__actions">
                        <Options items={
                          [
                            {
                              title: "Editar",
                              onClick: () => (window.location.href = `/foodstuffs/${foodstuff.id}/editing${getParams()}`),
                            },
                            {
                              title: "Duplicar",
                              onClick: () => { window.location.href = `/create-foodstuff?draftFoodstuffId=${foodstuff.id}` },
                            },
                            {
                              title: "Deletar",
                              onClick: () => {
                                setOpenDeleteConfirmModal(true);
                                setFoodstuffOnDelete(foodstuff);
                              },
                              className: "delete-option",
                            },
                          ]
                        } />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="foodstuffs__list__footer">
                  {currentFoodstuffsPage && currentFoodstuffsPage <= foodstuffsResponse.total_pages && (
                    <NumericPagination
                      currentPage={currentFoodstuffsPage === 0 ? 1 : Number(currentFoodstuffsPage)}
                      totalPages={foodstuffsResponse.total_pages}
                      onSelectPage={(page) => {
                        fetchFoodstuffs(searchedFoodstuffName, page);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Confirmação de exclusão"
        description={deleteDescription}
        onConfirm={handleDeleteFoodstuff}
        onCancel={() => {
          setOpenDeleteConfirmModal(false);
          setFoodstuffOnDelete();
        }}
        show={openDeleteConfirmModal}
        setShow={setOpenDeleteConfirmModal}
      />
    </>
  );
};

export default Foodstuffs;
