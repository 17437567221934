import "./DefaultButton.css";

import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import { types } from "util";

const DefaultButton = ({
  title,
  disabled,
  onClick,
  type,
  size,
  style,
}) => {
  const getTypeStyle = () => {
    let typeStyle = "";

    switch (type) {
      case "primary":
        typeStyle = "default-button";
        break;
      case "secondary":
        typeStyle = "default-button--secondary";
        break;
      case "tertiary":
        typeStyle = "default-button--tertiary";
        break;
      default:
        typeStyle = "default-button";
        break;
    }

    if (disabled) {
      typeStyle += ` ${typeStyle}--disabled`;
    }

    return `${typeStyle} ${style}`;
  };

  const getSizeStyle = () => {
    if (size === "small") {
      return "default-button--small";
    }
    return "";
  }

  const getStyle = () => {
    return `default-button ${getTypeStyle()} ${getSizeStyle()}`;
  };

  return (
    <div className={getStyle()}>
      <Button
        variant="primary"
        onClick={() => onClick()}
        disabled={disabled}
      >
        {title}
      </Button>
    </div>
  );
};

DefaultButton.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default DefaultButton;
