import "./App.css";

import React from "react";
import { GlobalProvider } from "./pages/GlobalContext";
import MainNavigator from "./pages/router/Navigator";

const App = () => {
  return (
    <GlobalProvider>
      <MainNavigator />
    </GlobalProvider>
  );
};

export default App;
