import "./Options.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import MoreHorizontalIcon from "../../assets/imgs/more-horizontal-icon.svg";

const Options = ({
  items,
}) => {
  const [openOptionsDropdown, setOpenOptionsDropdown] = useState(false);

  return (
    <>
      <div className="foodstuffs__list__item__actions__moreSection">
        <Button
          className="foodstuffs__list__item__actions__moreSection__icon"
          onClick={() => setOpenOptionsDropdown(prevState => !prevState)}
        >
          <img src={MoreHorizontalIcon} alt="More icon" />
        </Button>
      </div>
      <div className="foodstuffs__list__item__actions__moreSection__dropdown">
        <Dropdown
          show={openOptionsDropdown}
          onToggle={() => setOpenOptionsDropdown(prevState => !prevState)}
        >
          <Dropdown.Menu bsPrefix="dropdown-menu foodstuffs__list__item__actions__moreSection__dropdown">
            {items?.map((item, index) => (
              <Dropdown.Item
                key={index}
                onClick={item.onClick}
                className={`foodstuffs__list__item__actions__moreSection__dropdown__item ${item.className || ''}`}
              >
                {item.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

Options.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Options;
