import "./CreateBrandModal.css";

import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import TextInput from "../TextInput/TextInput";
import SuccessModal from "../SuccessModal/SuccessModal";
import ErrorModal from "../ErrorModal/ErrorModal";

import DefaultButton from "../DefaultButton/DefaultButton";

import { GlobalContext } from "../../pages/GlobalContext";
import brandService from "../../services/brand-service";

const CreateBrandModal = ({ 
  showModal,
  onClose,
 }) => {
  const [openModal, setOpenModal] = React.useState(showModal);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [successTitle, setSuccessTitle] = React.useState("");
  const [successDescription, setSuccessDescription] = React.useState("");
  const [showeErrorModal, setShowErrorModal] = React.useState(false);
  const [errorTitle, setErrorTitle] = React.useState("");
  const [errorDescription, setErrorDescription] = React.useState("");
  const [name, setName] = React.useState("");
  const { setIsLoading } = React.useContext(GlobalContext);

  const handleCloseModal = () => {
    setOpenModal(false);
    setName("");
    onClose();
  }

  const handleCreateBrand = async () => {
    setIsLoading(true);
    try {
      await brandService.createBrand(name);
      setSuccessTitle("Marca criada com sucesso!");
      setSuccessDescription("A marca foi criada com sucesso e já está disponível para ser utilizada.");
      setOpenModal(false);
      setShowSuccessModal(true);
    } catch (error) {
      setErrorTitle("Erro ao criar marca");
      setErrorDescription("Ocorreu um erro ao criar a marca, tente novamente mais tarde.");
      setOpenModal(false);
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setOpenModal(showModal);
  }, [showModal]);

  return (
    <>
      <div className="create-brand__modal">
        <Modal show={openModal} onHide={handleCloseModal} onExit={handleCloseModal} >
          <div className="create-brand__modal__header">
            <h4>Nova marca</h4>
          </div>
          <div className="create-brand__modal__content">
            <div className="create-brand__modal__search">
              <TextInput
                label="Nome"
                placeholder="Nome"
                onChangeText={(text) => setName(text)}
              />
            </div>
            <div className="create-brand__modal__buttons">
              <div className="create-brand__modal__button">
                <DefaultButton
                  title="Voltar"
                  onClick={handleCloseModal}
                  type="secondary"
                  size="medium"
                />
              </div>
              <div className="create-brand__modal__button">
                <DefaultButton
                  title="Criar"
                  onClick={handleCreateBrand}
                  disabled={!name}
                  type="primary"
                  size="medium"
                />
              </div>
            </div>
          </div>
        </Modal>
      </div >
      <SuccessModal
        title={successTitle}
        description={successDescription}
        show={showSuccessModal}
        confirmTitle="Voltar"
        onConfirm={() => setShowSuccessModal(false)}
        onBack={() => setShowSuccessModal(false)}
        onExit={() => setShowSuccessModal(false)}
      />
      <ErrorModal
        title={errorTitle}
        description={errorDescription}
        show={showeErrorModal}
        confirmTitle="Voltar"
        onConfirm={() => setShowErrorModal(false)}
        onExit={() => setShowErrorModal(false)}
        onBack={() => setShowErrorModal(false)}
      />
    </>
  );
};

CreateBrandModal.propTypes = {
  initialPhotoUrl: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default CreateBrandModal;
