import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const userService = {
  async getUsers(page, size, name) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get("/v1/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          name,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar usuários");
    }
  },
};

export default userService;