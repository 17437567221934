import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const foodstuffService = {
  async getFoodstuffs(page, size, name, withBarcode, withBrand) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get("/v1/foodstuffs", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          name_or_brand: name || null,
          with_brand: withBrand !== null ? withBrand : null,
          with_barcode: withBarcode !== null ? withBarcode : null,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar lista de alimentos");
    }
  },

  async getFoodstuffPhotos(page, size, tags) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.get("/v1/foodstuff-photos", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          tags,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao buscar imagens de alimentos");
    }
  },

  async getFoodstuffById(id) {
    const token = localStorage.getItem("token");

    const endpointRoute = `/v1/foodstuffs/${id}`;

    try {
      const response = await api.get(endpointRoute, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch {
      throw new Error("Erro ao buscar alimento");
    }
  },

  async createFoodstuff(foodstuff) {
    const token = localStorage.getItem("token");

    try {
      const response = await api.post("/v1/foodstuffs", foodstuff, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log('erro ao criar alimento', error)
      throw new Error("Erro ao criar alimento");
    }
  },

  async createFoodstuffPhoto(file, tags) {
    const token = localStorage.getItem("token");
    const formDataBody = new FormData();
    formDataBody.append("file", file);

    const response = await api.post(`/v1/foodstuff-photos?tags=${tags}`, formDataBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  },

  async updateFoodstuff(id, foodstuff) {
    const token = localStorage.getItem("token");
    const endpointRoute = `/v1/foodstuffs/${id}`;

    try {
      const response = await api.patch(endpointRoute, foodstuff, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log('erro ao atualizar alimento', error)
      throw new Error("Erro ao atualizar alimento");
    }
  },

  async deleteFoodstuff(id) {
    const token = localStorage.getItem("token");
    const endpointRoute = `/v1/foodstuffs/${id}`;

    try {
      const response = await api.delete(endpointRoute, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Erro ao deletar alimento");
    }
  }
};

export default foodstuffService;
