import "./Landing.css";
import "bootstrap/dist/css/bootstrap.min.css";

import TextLogo from "../../assets/imgs/text-logo.svg";
import Menu from "../../assets/imgs/menu.svg";

import CalorieTrackerFeature from "../../assets/imgs/calorie-tracker-feature.svg";
import MealTrackerFeature from "../../assets/imgs/meal-tracker-feature.svg";
import WaterTrackerFeature from "../../assets/imgs/water-tracker-feature.svg";
import CalorieTrackerFeatureMobile from "../../assets/imgs/calorie-tracker-feature-mobile.svg";
import MealTrackerFeatureMobile from "../../assets/imgs/meal-tracker-feature-mobile.svg";
import WaterTrackerFeatureMobile from "../../assets/imgs/water-tracker-feature-mobile.svg";
import CloseButton from "../../assets/imgs/close-button.svg";

import Divisor from "../../assets/imgs/Divisor.svg";
import VerticalDivisor from "../../assets/imgs/vertical-divisor.svg";
import DrawnAccents from "../../assets/imgs/drawn-accents.svg";

import FlavioLeviIcon from "../../assets/imgs/flavio-levi-icon.svg";
import MariaIsabelIcon from "../../assets/imgs/maria-isabel-icon.svg";
import CassioSpinaIcon from "../../assets/imgs/cassio-spina-icon.svg";

import StarIcon from "../../assets/imgs/star-icon.svg";
import ButtonChevronIcon from "../../assets/imgs/button-chevron-icon.svg";
import NutritionistImage from "../../assets/imgs/nutritionist-image.svg";
import NutritionistImageMobile from "../../assets/imgs/nutritionist-image-mobile.svg";
import HelpAvatars from "../../assets/imgs/help-avatars.svg";
import DevicesImage from "../../assets/imgs/devices-image.png";
import AppleDownloadButton from "../../assets/imgs/apple-download-button.svg";
import GoogleDownloadButton from "../../assets/imgs/google-download-button.svg";

import DefaultButton from "../../components/DefaultButton/DefaultButton";

import React, { useEffect } from "react";
import TextInput from "../../components/TextInput/TextInput";

const FeedbackItem = ({ stars, text, author }) => {
  return (
    <div className="landing__feedbacks__item">
      <div className="landing__feedbacks__item__stars">
        {Array.from({ length: stars }, (_, index) => (
          <img key={index} src={StarIcon} alt="star-icon" />
        ))}
      </div>
      <div className="landing__feedbacks__item__text">
        <p>{text}</p>
      </div>
      <div className="landing__feedbacks__item__author">
        <img src={author.image} alt="flavio-levi-image" />
        <p>{author.name}</p>
      </div>
    </div>
  );
};

const Landing = () => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const beginningRef = React.useRef(null);
  const functionalitiesRef = React.useRef(null);
  const contactRef = React.useRef(null);
  const downloadRef = React.useRef(null);
  const isRunningOnIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  const getCalorieTrackerFeature = () => {
    if (window.innerWidth <= 768) {
      return CalorieTrackerFeatureMobile;
    }
    return CalorieTrackerFeature;
  }

  const getMealTrackerFeature = () => {
    if (window.innerWidth <= 768) {
      return MealTrackerFeatureMobile;
    }
    return MealTrackerFeature;
  }

  const getWaterTrackerFeature = () => {
    if (window.innerWidth <= 768) {
      return WaterTrackerFeatureMobile;
    }
    return WaterTrackerFeature;
  }

  const getNutritionistImage = () => {
    if (window.innerWidth <= 768) {
      return NutritionistImageMobile;
    }
    return NutritionistImage;
  }

  return (
    <>
      {window.innerWidth <= 768 && openMenu ? (
        <div className="menu">
          <div className="menu__header">
            <div className="menu__header__close">
              <a href="javascript:;" onClick={() => setOpenMenu(false)}>
                <img src={CloseButton} alt="menu" />
              </a>
            </div>
          </div>
          <div className="menu__items">
            <a
              href="javascript:;"
              className="menu__item" onClick={() => {
                setOpenMenu(false);
                setTimeout(() => {
                  scrollToRef(beginningRef);
                }, 1);
              }}>
              <p>Início</p>
            </a>
            <a
              href="javascript:;"
              className="menu__item"
              onClick={() => {
                setOpenMenu(false);
                setTimeout(() => {
                  scrollToRef(functionalitiesRef);
                }, 1);
              }}
            >
              <p>Funcionalidades</p>
            </a>
            <a
              href="javascript:;"
              className="menu__item"
              onClick={() => {
                setOpenMenu(false);
                setTimeout(() => {
                  scrollToRef(contactRef);
                }, 1);
              }}
            >
              <p>Contato</p>
            </a>
          </div>
          <div className="menu__actions">
            <DefaultButton
              title="Baixar App"
              onClick={() => {
                setOpenMenu(false);
                if (isRunningOnIOS()) {
                  window.location.href = "https://apps.apple.com/br/app/lorie/id6596730043?l=en-GB";
                } else {
                  setTimeout(() => {
                    scrollToRef(downloadRef);
                  }, 1);
                }
              }}
            />
            <DefaultButton
              title="Entrar como nutricionista"
              onClick={() => {
                window.location.href = "/login";
              }}
              type="secondary"
              style="menu__actions__login"
            />
          </div>
        </div>
      ) : (
        <div className="landing">
          <div className="landing__header">
            <a href="javascript:;" className="landing__header__logo">
              <img src={TextLogo} alt="logo" />
            </a>
            <div className="landing__header__menu">
              <a href="javascript:;" className="landing__header__menu__item" onClick={() => scrollToRef(beginningRef)}><p>Início</p></a>
              <a href="javascript:;" className="landing__header__menu__item" onClick={() => scrollToRef(functionalitiesRef)}><p>Funcionalidades</p></a>
              <a href="javascript:;" className="landing__header__menu__item" onClick={() => scrollToRef(contactRef)}><p>Contato</p></a>
            </div>
            <div className="landing__header__actions">
              {window.innerWidth >= 768 ? (
                <>
                  <DefaultButton
                    title="Entrar como nutricionista"
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                    type="secondary"
                    style="landing__header__actions__login"
                  />
                  <DefaultButton
                    title="Baixar App"
                    onClick={() => scrollToRef(downloadRef)}
                  />
                </>
              ) : (
                <a href="javascript:;" onClick={() => setOpenMenu(true)}>
                  <img src={Menu} alt="menu" />
                </a>
              )}
            </div>
          </div>
          <div className="landing__introduction" ref={beginningRef}>
            <div className="landing__introduction__title">
              <h1>Transforme sua rotina de saúde e bem-estar com a Lorie!</h1>
            </div>
            <div className="landing__introduction__description">
              <p>A ferramenta perfeita pra alcançar seus objetivos de saúde de forma fácil e divertida</p>
            </div>
            <div className="landing__introduction__actions">
              <div className="landing__introduction__button">
                <DefaultButton
                  title="Conheça mais"
                  onClick={() => { }}
                />
              </div>
            </div>
          </div>
          <div className="landing__sample" ref={functionalitiesRef}>
            <div className="landing__sample__content">
              <div className="landing__sample__items">
                <div className="landing__sample__item landing__sample__calorie-tracker">
                  <img src={getCalorieTrackerFeature()} alt="calorie-tracker" />
                </div>
                <div className="landing__sample__item landing__sample__meal-tracker">
                  <img src={getWaterTrackerFeature()} alt="meal-tracker" />
                </div>
                <div className="landing__sample__item landing__sample__water-tracker">
                  <img src={getMealTrackerFeature()} alt="water-tracker" />
                </div>
              </div>
            </div>
          </div>
          <div className="landing__how-it-works">
            <div className="landing__how-it-works__indicator">
              <h2>COMO FUNCIONA</h2>
            </div>
            <div className="landing__how-it-works__title">
              <h1>Sua jornada nutricional começa agora.</h1>
            </div>
            <div className="landing__how-it-works__content">
              <div className="landing__how-it-works__line">
                <img src={Divisor} alt="divisor" />
              </div>
              <div className="landing__how-it-works__vertical-line">
                <img src={VerticalDivisor} alt="vertical-divisor" />
              </div>
              <div className="landing__how-it-works__items">
                <div className="landing__how-it-works__item landing__how-it-works__basic">
                  <div className="landing__how-it-works__item__draw">
                    <div className="landing__how-it-works__item__number">
                      <p>1</p>
                    </div>
                  </div>
                  <div className="landing__how-it-works__item__content">
                    <h3>Comece com o básico</h3>
                    <p>Insira suas informações pessoais para calcular suas necessidades nutricionais de forma precisa.</p>
                  </div>
                </div>
                <div className="landing__how-it-works__item landing__how-it-works__meals">
                  <div className="landing__how-it-works__item__draw">
                    <div className="landing__how-it-works__item__number">
                      <p>2</p>
                    </div>
                  </div>
                  <div className="landing__how-it-works__item__content">
                    <h3>Registre suas refeições</h3>
                    <p>Adicione os alimentos que você consome ao longo do dia. Nossa base de dados inclui uma vasta variedade de alimentos.</p>
                  </div>
                </div>
                <div className="landing__how-it-works__item landing__how-it-works__progress">
                  <div className="landing__how-it-works__item__draw">
                    <div className="landing__how-it-works__item__number">
                      <p>3</p>
                    </div>
                    <img src={DrawnAccents} alt="drawn-accents" />
                  </div>
                  <div className="landing__how-it-works__item__content">
                    <h3>Acompanhe seu progresso</h3>
                    <p>Veja seu progresso e receba recomendações personalizadas para alcançar seus objetivos.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landing__feedbacks">
            <div className="landing__feedbacks__content">
              <div className="landing__feedbacks__items">
                <FeedbackItem
                  stars={5}
                  text="A Lorie me ajudou a perder 5kg em 2 meses! A interface é super intuitiva e fácil de usar."
                  author={{
                    image: FlavioLeviIcon,
                    name: "Flávio Levi",
                  }}
                />
                <FeedbackItem
                  stars={5}
                  text="Adoro como posso acompanhar tudo o que como e bebo. A Lorie me mantém motivada!"
                  author={{
                    image: MariaIsabelIcon,
                    name: "Maria Isabel",
                  }}
                />
                <FeedbackItem
                  stars={5}
                  text="As recomendações personalizadas são incríveis. Sinto-me mais saudável e energizado!"
                  author={{
                    image: CassioSpinaIcon,
                    name: "Cássio Spina",
                  }}
                />
                <FeedbackItem
                  stars={5}
                  text="As recomendações personalizadas são incríveis. Sinto-me mais saudável e energizado!"
                  author={{
                    image: CassioSpinaIcon,
                    name: "Cássio Spina",
                  }}
                />
                <FeedbackItem
                  stars={5}
                  text="As recomendações personalizadas são incríveis. Sinto-me mais saudável e energizado!"
                  author={{
                    image: CassioSpinaIcon,
                    name: "Cássio Spina",
                  }}
                />
              </div>
              <div className="landing__feedbacks__actions">
                <a href="javascript:;">
                  <div className="landing__feedbacks__button">
                    <img src={ButtonChevronIcon} alt="chevron-right-icon" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="landing__nutritionists">
            <div className="landing__nutritionists__title">
              <h1>Para nutricionistas</h1>
            </div>
            <div className="landing__nutritionists__description">
              <p>Potencialize seu atendimento com a Lorie</p>
            </div>
            <div className="landing__nutritionists__content">
              <div className="landing__nutritionists__paragraphs">
                <div className="landing__nutritionists__paragraph">
                  <h3>Gerencie seus pacientes com eficiência</h3>
                  <p>Facilite o acompanhamento das dietas e hábitos alimentares dos seus pacientes com uma plataforma intuitiva e integrada.</p>
                </div>
                <div className="landing__nutritionists__paragraph">
                  <h3>Personalização de planos alimentares</h3>
                  <p>Crie e adapte planos nutricionais personalizados com base nas necessidades específicas de cada paciente, monitorando o consumo de calorias e macronutrientes em tempo real.</p>
                </div>
                <div className="landing__nutritionists__paragraph">
                  <h3>Monitoramento contínuo</h3>
                  <p>Acompanhe o progresso dos seus pacientes com relatórios detalhados e insights valiosos, ajudando-os a atingir seus objetivos de forma mais eficaz.</p>
                </div>
              </div>
              <div className="landing__nutritionists__image">
                <img src={getNutritionistImage()} alt="nutritionist-image" />
              </div>
            </div>
          </div>
          <div className="landing__help" ref={contactRef}>
            <div className="landing__help__content">
              <div className="landing__help__image">
                <img src={HelpAvatars} alt="help-avatars" />
              </div>
              <div className="landing__help__title">
                <h1>Precisa de ajuda?</h1>
              </div>
              <div className="landing__help__description">
                <p>Nossa equipe de suporte está sempre à disposição. Preencha seu email abaixo que entraremos em contato em breve.</p>
              </div>
            </div>
            <div className="landing__help__form">
              <TextInput
                label="Nome"
                placeholder="Nome"
                onChangeText={() => { }}
              />
              <TextInput
                style="landing__help__form__input"
                label="E-mail"
                placeholder="E-mail"
                onChangeText={() => { }}
              />
              <TextInput
                style="landing__help__form__input"
                label="Assunto"
                placeholder="Assunto"
                onChangeText={() => { }}
              />
              <TextInput
                style="landing__help__form__inputArea"
                label="Mensagem"
                placeholder="Mensagem"
                onChangeText={() => { }}
              />
              <div className="landing__help__button">
                <DefaultButton
                  title="Enviar"
                  onClick={() => { }}
                />
              </div>
            </div>
          </div>
          <div className="landing__download" ref={downloadRef}>
            <div className="landing__download__content">
              <div className="landing__download__image">
                <img src={DevicesImage} alt="devices-image" />
              </div>
              <div className="landing__download__info">
                <div className="landing__download__title">
                  <h1>Baixe o App Lorie Agora!</h1>
                </div>
                <div className="landing__download__description">
                  <p>A Lorie é o aplicativo ideal para quem quer viver de forma mais saudável. Controle suas calorias, registre suas refeições e fique de olho na sua hidratação.</p>
                </div>
                <div className="landing__download__buttons">
                  <a href="https://apps.apple.com/br/app/lorie/id6596730043?l=en-GB" target="_blank" rel="noreferrer">
                    <img src={AppleDownloadButton} alt="apple-download-button" className="landing__download__apple" />
                  </a>
                  <img src={GoogleDownloadButton} alt="google-download-button" className="landing__download__google" />
                </div>
              </div>
            </div>
          </div>
          <div className="landing__footer">
            <div className="landing__footer__logoSection">
              <a href="javascript:;" className="landing__footer__logo">
                <img src={TextLogo} alt="logo" />
              </a>
              <div className="landing__footer__copyright">
                <p>© 2024 Lorie. Todos os direitos reservados.</p>
              </div>
            </div>
            <a href="javascript:;" className="landing__footer__termsAndPrivacy" onClick={() => {
              window.location.href = "/privacy-policy";
            }}>
              <p>Termos e privacidade</p>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default Landing;

Landing.propTypes = {};
