import "./ImageSelector.css";

import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import TextInput from "../TextInput/TextInput";
import SuccessModal from "../SuccessModal/SuccessModal";
import ErrorModal from "../ErrorModal/ErrorModal";

import DefaultButton from "../DefaultButton/DefaultButton";
import ExportImageIcon from "../../assets/imgs/export-image-icon.svg";
import RemoveIcon from "../../assets/imgs/remove-icon.svg";

import foodstuffService from "../../services/foodstuff-service";
import { useDropzone } from "react-dropzone";
import NumericPagination from "../NumericPagination/NumericPagination";
import { GlobalContext } from "../../pages/GlobalContext";

const ImageSelector = ({ initialPhotoUrl, onSelect }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [foodstuffPhotos, setFoodstuffPhotos] = React.useState([]);
  const [selectedFoodstuffPhoto, setSelectedFoodstuffPhoto] = React.useState(null);
  const [foodstuffPhotosResponse, setFoodstuffPhotosResponse] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [typedSearchByTags, setTypedSearchByTags] = React.useState("");
  const [selectedPhotoUrl, setSelectedPhotoUrl] = React.useState(initialPhotoUrl ?? undefined);
  const [currentScreen, setCurrentScreen] = React.useState("select-image");
  const [newTags, setNewTags] = React.useState([]);
  const [typedNewTagsByComma, setTypedNewTagsByComma] = React.useState("");
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [successTitle, setSuccessTitle] = React.useState("");
  const [successDescription, setSuccessDescription] = React.useState("");
  const [showeErrorModal, setShowErrorModal] = React.useState(false);
  const [errorTitle, setErrorTitle] = React.useState("");
  const [errorDescription, setErrorDescription] = React.useState("");
  const { setIsLoading } = React.useContext(GlobalContext);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUploadedImage({
        file: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
  })

  const defaultPageSize = 30;

  const handleSelectPhoto = (photo) => {
    setSelectedPhotoUrl(photo.url);
    setSelectedFoodstuffPhoto(photo);
    onSelect(photo);
    setOpenModal(false);
  }

  const fetchFoodstuffPhotos = async () => {
    try {
      setIsLoading(true);
      const response = await foodstuffService.getFoodstuffPhotos(currentPage - 1, defaultPageSize, typedSearchByTags);
      setFoodstuffPhotos(response?.content);
      setFoodstuffPhotosResponse(response);
    } catch (error) {
      console.log("erro ao buscar imagens", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleUploadImage = async () => {
    try {
      const tags = newTags.join(",");
      await foodstuffService.createFoodstuffPhoto(uploadedImage.file, tags);
      setOpenModal(false);
      setShowSuccessModal(true);
      setSuccessTitle("Sucesso!");
      setSuccessDescription("Imagem cadastrada com sucesso!");
      setUploadedImage(null);
      setNewTags([]);
      await fetchFoodstuffPhotos();
    } catch (error) {
      setShowErrorModal(true);
      setErrorTitle("Erro ao cadastrar imagem");
      setErrorDescription("Ocorreu um erro ao cadastrar a imagem. Tente novamente mais tarde.");
      console.log("erro ao criar imagem", error);
    }
  }

  const handleAddTag = (tag) => {
    const tagIsComplete = tag?.includes(",");
    if (tagIsComplete) {
      const typedTags = tag?.split(",")?.map((currentTag) => currentTag.trim()) || [];
      const newTag = typedTags[0];
      if (newTags.includes(newTag)) {
        return;
      }

      setNewTags((prevTags) => [...prevTags, newTag]);
      setTypedNewTagsByComma("");
      return;
    }

    setTypedNewTagsByComma(tag);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentScreen("select-image");
    setUploadedImage(null);
    setNewTags([]);
    setCurrentPage(1);
  }

  const handleRemoveTag = (tag) => {
    setNewTags((prevTags) => prevTags.filter((currentTag) => currentTag !== tag));
  }

  useEffect(() => {
    Promise.resolve(fetchFoodstuffPhotos());
  }, [typedSearchByTags, currentPage]);

  useEffect(() => {
    Promise.resolve(fetchFoodstuffPhotos());
    setSelectedPhotoUrl(initialPhotoUrl);
  }, []);

  useEffect(() => {
    setSelectedPhotoUrl(initialPhotoUrl);
    console.log("initialPhotoUrl", initialPhotoUrl)
  }, [initialPhotoUrl]);

  return (
    <>
      <a href="javascript:;" onClick={() => setOpenModal(true)} className="image-selector">
        {selectedFoodstuffPhoto || selectedPhotoUrl ? (
          <div className="image-selector__img">
            <img src={selectedFoodstuffPhoto?.photo_url || selectedPhotoUrl} alt={selectedFoodstuffPhoto?.tags || "imagem selecionada"} />
          </div>
        ) : (
          <div className="image-selector__text">
            <p>Selecionar imagem</p>
          </div>
        )}
      </a>
      <div className="image-selector__modal">
        <Modal show={openModal} onHide={handleCloseModal} onExit={handleCloseModal} >
          {currentScreen === "select-image" && (
            <>
              <div className="image-selector__modal__header">
                <h4>Selecione uma imagem</h4>
              </div>
              <div className="image-selector__modal__content">
                <div className="image-selector__modal__search">
                  <TextInput
                    label="Buscar imagem"
                    placeholder="Buscar por nome ou características"
                    onChangeText={(text) => setTypedSearchByTags(text)}
                  />
                </div>
                <div className="image-selector__modal__images">
                  <div className="image-selector__modal__images__header">
                    <div className="image-selector__modal__images__info">
                      <div className="image-selector__modal__images__title">
                        <p>Imagens</p>
                      </div>
                      <div className="image-selector__modal__images__counter">
                        <p>({foodstuffPhotosResponse?.total_elements ?? 0})</p>
                      </div>
                    </div>
                    <div className="image-selector__modal__images__button">
                      <DefaultButton
                        title="Nova imagem"
                        onClick={() => setCurrentScreen("create-image")}
                        type="tertiary"
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="image-selector__modal__images__list">
                    <div className="image-selector__modal__images__items">
                      {foodstuffPhotos?.map((photo) => (
                        <a
                          href="javascript:;"
                          onClick={() => handleSelectPhoto(photo)}
                          className={`image-selector__modal__images__item ${selectedPhotoUrl === photo.url && "selected-photo"}`}
                          key={photo.id}
                        >
                          <img src={photo.url} alt={photo.tags} />
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="image-selector__modal__images__pagination">
                    <NumericPagination
                      currentPage={currentPage}
                      totalPages={foodstuffPhotosResponse?.total_pages}
                      onSelectPage={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {currentScreen === "create-image" && (
            <>
              <div className="image-creator__modal__header">
                <h4>Nova imagem</h4>
              </div>
              <div className="image-creator__modal__content">
                <div {...getRootProps({ className: 'dropzone' })}>
                  {uploadedImage && (
                    <div className="image-creator__modal__uploaded">
                      <div className="image-creator__modal__upload__preview">
                        <img src={uploadedImage.preview} alt="Imagem importada" />
                      </div>
                      <div className="image-creator__modal__upload__info">
                        <div className="image-creator__modal__upload__info__title">
                          <p>{uploadedImage.file.name}</p>
                        </div>
                        <a
                          href="javascript:;"
                          className="image-creator__modal__upload__info__remove"
                          onClick={() => setUploadedImage(null)}
                        >
                          <img src={RemoveIcon} alt="Exportar imagem" />
                        </a>
                      </div>
                    </div>
                  )}
                  {!uploadedImage && (
                    <a href="javascript:;" className={`image-creator__modal__upload ${isDragging && 'image-creator__modal__upload--dragging'}`}>

                      <div className="image-creator__modal__upload__icon">
                        <img src={ExportImageIcon} alt="Exportar imagem" />
                      </div>
                      <div className="image-creator__modal__upload__title">
                        <p>Importar imagem</p>
                      </div>
                      <div className="image-creator__modal__upload__description">
                        {isDragging ? (
                          <p>Solte a imagem aqui</p>
                        ) : (
                          <p>Arraste uma imagem aqui ou clique para selecionar</p>
                        )}
                      </div>
                    </a>
                  )}
                </div>
                <div className="image-creator__modal__tags">
                  <div className="image-creator__modal__tags__title">
                    <p>Tags</p>
                  </div>
                  <div className="image-creator__modal__tags__description">
                    <p>Características do alimento separadas por vírgula</p>
                  </div>
                  <div className="image-creator__modal__tags__input">
                    <TextInput
                      label="Tags"
                      placeholder="Digite as características do alimento"
                      onChangeText={(text) => handleAddTag(text)}
                      value={typedNewTagsByComma}
                    />
                  </div>
                  <div className="image-creator__modal__tags__items">
                    {newTags?.map((tag) => (
                      <div className="image-creator__modal__tags__item" key={tag}>
                        <div className="image-creator__modal__tags__item__tag">
                          <p>{tag}</p>
                        </div>
                        <a
                          href="javascript:;"
                          onClick={() => handleRemoveTag(tag)}
                          className="image-creator__modal__tags__item__remove"
                        >
                          <img src={RemoveIcon} alt="Remover tag" />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="image-creator__modal__buttons">
                  <div className="image-creator__modal__button">
                    <DefaultButton
                      title="Voltar"
                      onClick={() => {
                        setCurrentScreen("select-image");
                        setUploadedImage(null);
                        setNewTags([]);
                      }}
                      type="secondary"
                      size="medium"
                    />
                  </div>
                  <div className="image-creator__modal__button">
                    <DefaultButton
                      title="Salvar"
                      onClick={handleUploadImage}
                      disabled={newTags.length === 0 || !uploadedImage}
                      type="primary"
                      size="medium"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>
      </div >
      <SuccessModal
        title={successTitle}
        description={successDescription}
        show={showSuccessModal}
        confirmTitle="Voltar para a seleção"
        backTitle={"Cadastrar outra imagem"}
        onConfirm={() => {
          setShowSuccessModal(false);
          setCurrentScreen("select-image");
          setOpenModal(true);
        }}
        onBack={() => {
          setShowSuccessModal(false);
          setCurrentScreen("create-image");
          setOpenModal(true);
        }}
        onExit={() => {
          setShowSuccessModal(false)
          setCurrentScreen("select-image");
          setOpenModal(true);
        }}
      />
      <ErrorModal
        title={errorTitle}
        description={errorDescription}
        show={showeErrorModal}
        confirmTitle="Fechar"
        onConfirm={() => setShowErrorModal(false)}
        onExit={() => setShowErrorModal(false)}
      />
    </>
  );
};

ImageSelector.propTypes = {
  initialPhotoUrl: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default ImageSelector;
