import "./Navigator.css";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../login/Login";
import Home from "../home/Home";
import Landing from "../landing/Landing";
import UpdateFoodstuff from "../update-foodstuff/UpdateFoodstuff";
import SideMenu from "../../components/SideMenu/SideMenu";
import CreateFoodstuff from "../create-foodstuff/CreateFoodstuff";
import Foodstuffs from "../foodstuffs/Foodstuffs";
import loginService from "../../services/login-service";
import TopMenu from "../../components/TopMenu/TopMenu";
import { GlobalContext } from "../GlobalContext";
import { BounceLoader } from "react-spinners";
import Privacy from "../privacy/Privacy";

const MainNavigator = () => {
  const currentPageFromPath = window.location.pathname.split("/")[1];
  const [currentUser, setCurrentUser] = useState();
  const { setIsLoading, isLoading } = useContext(GlobalContext);
  const screenRequiresLogin = currentPageFromPath !== "login" && currentPageFromPath && currentPageFromPath !== "" && currentPageFromPath !== "privacy-policy";

  useEffect(() => {
    async function fetchCurrentUser() {
      try {
        const loggedInUser = await loginService.getCurrentUser();

        if (!loggedInUser && screenRequiresLogin) {
          window.location.assign("/login");
        }

        if (loggedInUser && currentPageFromPath === "login") {
          window.location.assign("/home");
        }

        setCurrentUser(loggedInUser);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    if (!currentUser) {
      Promise.resolve(fetchCurrentUser());
    }
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loader">
          <BounceLoader
            color={"#ef768a"}
            loading={isLoading}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {currentUser && (
        <>
          {screenRequiresLogin ? (
            <Router>
              {/* <SideMenu /> */}
              <div className="app-content">
                <TopMenu />
                <Routes>
                  <Route exact path="/" element={<Landing />} />
                  <Route exact path="/privacy-policy" element={<Privacy />} />
                  <Route exact path="/dashboard" element={<Home />} />
                  <Route exact path="/foodstuffs" element={<Foodstuffs />} />
                  <Route
                    exact
                    path="/foodstuffs/:id/editing"
                    element={<UpdateFoodstuff />}
                  />
                  <Route
                    exact
                    path="/create-foodstuff"
                    element={<CreateFoodstuff />}
                  />
                  <Route
                    path="*"
                    element={<Home />}
                  />
                </Routes>
              </div>
            </Router>
          ) : (
            <Router>
              <div>
                <Routes>
                  <Route exact path="/" element={<Landing />} />
                  <Route exact path="/privacy-policy" element={<Privacy />} />
                  <Route exact path="/login" element={<Login />} />
                </Routes>
              </div>
            </Router>
          )}
        </>
      )}
      {!currentUser && (
        <Router>
          <div>
            <Routes>
              <Route exact path="/" element={<Landing />} />
              <Route exact path="/privacy-policy" element={<Privacy />} />
              <Route exact path="/login" element={<Login />} />
            </Routes>
          </div>
          {isLoading && (
            <div className="loader">
              <BounceLoader
                color={"#ef768a"}
                loading={isLoading}
                size={80}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )};
        </Router>
      )}
    </>
  )
};

export default MainNavigator;
