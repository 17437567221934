import "./SimplePagination.css";

import React from "react";
import PropTypes from "prop-types";

import "bootstrap/dist/css/bootstrap.min.css";
import DefaultButton from "../DefaultButton/DefaultButton";

const SimplePagination = ({ currentPage, totalPages, onSelectPage }) => {
  const handlePreviousFoodstuffsPage = async () => {
    const newPage = currentPage - 1;
    onSelectPage(newPage);
  };

  const handleNextFoodstuffsPage = async () => {
    const newPage = currentPage + 1;
    onSelectPage(newPage);
  };

  return (
    <div className="simple-pagination">
      <div className="simple-pagination__previous">
        <DefaultButton
          title="Anterior"
          onClick={handlePreviousFoodstuffsPage}
          disabled={currentPage === 0}
          type="secondary"
        />
      </div>
      <div className="simple-pagination__description">
        Página {currentPage + 1} de {totalPages}
      </div>
      <div className="simple-pagination__next">
        <DefaultButton
          title="Próximo"
          onClick={handleNextFoodstuffsPage}
          disabled={
            currentPage + 1 ===
            totalPages
          }
          type="secondary"
        />
      </div>
    </div>
  )
}

SimplePagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onSelectPage: PropTypes.func,
};

export default SimplePagination;